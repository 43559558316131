import React, { createContext, useContext, useState } from 'react';

interface ErrorContextType {
  show403Popup: boolean;
  setShow403Popup: (show: boolean) => void;
}

export let setGlobal403Popup: (show: boolean) => void;

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const ErrorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [show403Popup, setShow403Popup] = useState(false);
  setGlobal403Popup = setShow403Popup;
  return (
    <ErrorContext.Provider value={{ show403Popup, setShow403Popup }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useErrorContext = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('useErrorContext must be used within an ErrorProvider');
  }
  return context;
};
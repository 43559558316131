import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    styled
} from '@mui/material';

// Inline type definitions
interface ProcessedData {
    purchase_order_number: string;
    status: string;
    short_message: string;
}

interface UnprocessedData {
    entry: {
        [key: string]: string;
    };
    reason: string;
}

interface UploadResultPopupProps {
    open: boolean;
    onClose: () => void;
    processedData: ProcessedData[];
    unprocessedData: UnprocessedData[];
}

// Styled components for better table appearance
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const UploadResultPopup: React.FC<UploadResultPopupProps> = ({ open, onClose, processedData, unprocessedData }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>Upload Result</DialogTitle>
            <DialogContent>
                <Typography variant="h6" gutterBottom>Processed Data</Typography>
                <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Purchase Order Number</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Message</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {processedData.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <TableCell>{row.purchase_order_number}</TableCell>
                                    <TableCell>{row.status}</TableCell>
                                    <TableCell>{row.short_message}</TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant="h6" gutterBottom>Unprocessed Data</Typography>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Entry</StyledTableCell>
                                <StyledTableCell>Reason</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {unprocessedData.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <TableCell>
                                        <Table size="small">
                                            {/* <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>Field</StyledTableCell>
                                                    <StyledTableCell>Value</StyledTableCell>
                                                </TableRow>
                                            </TableHead> */}
                                            <TableBody>
                                                {Object.entries(row.entry).map(([key, value]) => (
                                                    <TableRow key={key}>
                                                        <TableCell component="th" scope="row">{key}</TableCell>
                                                        <TableCell>{String(value)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                    <TableCell>{row.reason}</TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UploadResultPopup;
// src/components/BackButton.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackButton: React.FC<{ redirectTo: string }> = ({ redirectTo }) => {
    const navigate = useNavigate();

    return (
        <IconButton onClick={() => navigate(redirectTo)} style={{ padding: 0 }}>
            <ArrowBackIcon color="primary" />
        </IconButton>
    );
};

export default BackButton;


// import BackButton from '../../../components/BackButton';
// <Box display="flex" alignItems="center" gap={2}>
//                 <BackButton redirectTo="/reports" />
//                     <Typography variant="h6" className="breadcrumb">
//                         Monthwise Report for Individual SKU
//                     </Typography>
//                     </Box>

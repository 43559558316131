import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Backdrop,
} from "@mui/material";
import { ErrorProvider, useErrorContext } from "./ErrorContext";
import { useEffect } from "react";

const ForbiddenPopup: React.FC = () => {
  const { show403Popup, setShow403Popup } = useErrorContext();

  useEffect(() => {}, [setShow403Popup]);

  const handleClose = () => {
    sessionStorage.clear();
    window.location.href = "/";
    setShow403Popup(false);
  };

  return (
    <Dialog
      open={show403Popup}
      onClose={(e, reason) =>
        reason !== "backdropClick" && setShow403Popup(false)
      }
      BackdropProps={{
        onClick: (e) => e.stopPropagation(),
      }}
    >
      <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
        Access Denied
      </DialogTitle>
      <DialogContent>
        <p>Please access the website from a valid IP address.</p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "#EC1B3B",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#EC1B3B",
              color: "#fff",
            },
          }}
          variant="contained"
        >
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForbiddenPopup;

import React, { useState, useEffect } from 'react';
import { Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomTable, { ColumnConfig } from "../../components/CustomTable";
import Header from '../dashboard/components/Header';
import PlusRoundIcon from '@rsuite/icons/PlusRound';
import { fetchUsers } from '../../utils/services/apiService';
import './UserManagement.css';

interface ApiUserData {
    user_id: number;
    user_full_name: string;
    user_email: string;
    user_mobile_number: string;
    user_type: string;
    user_code: string | null;
    binding_ip: string;
    created_at: string;
    updated_at: string;
}

interface ApiResponse {
    status: number;
    message: string;
    data: ApiUserData[];
}

interface UserData {
    id: string;
    sr_no: number;
    full_name: string;
    user_id: string;
    email: string;
    mobile: string;
    role: string;
    ip_binding: string;
}

const UserManagement: React.FC = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<UserData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = async () => {
        try {
            setLoading(true);
            setError(null);
            
            const response = await fetchUsers();
            
            if (response.status === 200 && response.data.status === 200) {
                const transformedData: UserData[] = response.data.data.map((user: ApiUserData, index: number) => ({
                    id: user.user_id.toString(),
                    sr_no: index + 1,
                    full_name: user.user_full_name,
                    user_id: user.user_code || '-',
                    email: user.user_email,
                    mobile: user.user_mobile_number,
                    role: user.user_type.charAt(0).toUpperCase() + user.user_type.slice(1), // Capitalize first letter
                    ip_binding: user.binding_ip
                }));
                
                setData(transformedData);
            } else {
                setError(response.data.message || 'Failed to fetch users. Please try again later.');
            }
        } catch (err) {
            console.error('Error fetching users:', err);
            setError('An error occurred while fetching users. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const columns: ColumnConfig[] = [
        {
            field: 'sr_no',
            headerName: 'Sr No.',
            width: 80,
            renderCell: (params) => <div className="user-management-cell-left">{params.value}</div>
        },
        {
            field: 'full_name',
            headerName: 'Name',
            width: 150,
            renderCell: (params) => <div className="cell-left">{params.value}</div>
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 210,
            renderCell: (params) => <div className="cell-left">{params.value}</div>
        },
        {
            field: 'mobile',
            headerName: 'Mobile',
            width: 130,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'user_id',
            headerName: 'User ID',
            width: 100,
            renderCell: (params) => <div className="cell-left">{params.value}</div>
        },
        {
            field: 'role',
            headerName: 'Role',
            width: 120,
            renderCell: (params) => (
                <div className={`cell-center ${params.value.toLowerCase()}-role`}>
                    {params.value}
                </div>
            )
        },
        {
            field: 'ip_binding',
            headerName: 'IP Binding',
            width: 130,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        }
    ];

    return (
        <div className="user-management-screen">
            <Header />
            <div className="user-management-table">
                <div className="user-management-table-header-container">
                    <Typography variant="body1" className="user-management-breadcrumb">
                        User Management
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => navigate('/create-user')}
                        size="large"
                        sx={{
                            backgroundColor: '#EC1B3B',
                            color: 'white',
                            borderRadius: '10px',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: '#EC1B3B'
                            }
                        }}
                        aria-label="Create new user"
                    >
                        <PlusRoundIcon style={{ marginRight: '10px' }} />
                        Create User
                    </Button>
                </div>
                
                {error && (
                    <Typography color="error" sx={{ mt: 2, mb: 2 }}>
                        {error}
                    </Typography>
                )}
                
                <div className="user-management-custom-table">
                    <CustomTable
                        editMode="row"
                        data={data}
                        columns={columns}
                        loading={loading}
                        getRowId={(data: any) => data.id}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserManagement;
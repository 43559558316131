import React, { useState, useEffect } from 'react';
import CustomTable, { ColumnConfig } from "../../components/CustomTable";
import { Typography, Button, Snackbar } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CheckCircle, XCircle } from 'lucide-react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getQrHistory, raiseRequestForPoItem, approveRequestForPoItem } from '../../utils/services/apiService';
import './QRHistory.css';
import Header from '../dashboard/components/Header';

interface APIResponse {
    purchase_order_number: string;
    purchase_order_date: string;
    vednor_name: string;
    sku_code: string;
    sku_name: string;
    generation_date: string;
    request_raised: string;
}

interface QRHistoryData {
    purchaseOrderNumber: string;
    purchaseOrderDate: string;
    vendorName: string;
    skuCode: string;
    skuName: string;
    category: string;
    subCategory: string;
    generationDate: string;
    generatedBy: string;
    status: string;
    requestStatus?: 'pending' | 'approved' | 'rejected' | null;
}

const QRHistory: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { itemCode } = useParams<{ itemCode?: string }>();
    const [data, setData] = useState<QRHistoryData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [userType] = useState<string | null>(sessionStorage.getItem('userType'));
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const showNotification = (message: string) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const transformAPIResponse = (apiData: APIResponse[]): QRHistoryData[] => {
        return apiData.map((item) => ({
            id: apiData.indexOf(item),
            purchaseOrderNumber: item.purchase_order_number,
            purchaseOrderDate: item.purchase_order_date,
            vendorName: item.vednor_name,
            skuCode: item.sku_code,
            skuName: item.sku_name,
            category: '',
            subCategory: '',
            generationDate: item.generation_date,
            generatedBy: 'vendor',
            status: 'Downloaded',
            requestStatus: null,
            requestRaised: item.request_raised
        }));
    };

    const fetchQrHistory = async () => {
        try {
            setLoading(true);
            const response = await getQrHistory();
            
            if (response.status === 200 && response.data?.data?.data) {
                const historyData = transformAPIResponse(response.data.data.data);
                const filteredData = userType === 'vendor'
                    ? historyData.filter((item: QRHistoryData) => item.generatedBy === 'vendor')
                    : historyData;
                    console.log(filteredData)

                setData(filteredData);
            } else {
                setError('Failed to fetch QR history');
            }
        } catch (err) {
            setError('Error fetching QR history');
            console.error('Error fetching QR history:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchQrHistory();
    }, [location, itemCode, userType]);

    const handleRequestAccess = async (purchaseOrderNumber: string, skuCode: string) => {
        try {
            setLoading(true);
            const response = await raiseRequestForPoItem({
                purchase_order_number: purchaseOrderNumber,
                item_code_number: skuCode
            });

            if (response.status === 200) {
                toast.success('Access request sent successfully');
                setData(prevData => 
                    prevData.map(item => 
                        item.purchaseOrderNumber === purchaseOrderNumber && item.skuCode === skuCode
                            ? { ...item, requestStatus: 'pending' }
                            : item
                    )
                );
            } else {
                toast.error('Failed to send access request');
            }
        } catch (err) {
            console.error('Error requesting access:', err);
            toast.error('Error requesting access');
        } finally {
            setLoading(false);
        }
    };

    const handleApprove = async (purchaseOrderNumber: string, skuCode: string) => {
        try {
            setLoading(true);
            const response = await approveRequestForPoItem({
                purchase_order_number: purchaseOrderNumber,
                item_code_number: skuCode
            });

            if (response.status === 200) {
                toast.success('Request approved successfully');
                setData(prevData => 
                    prevData.filter(item => 
                        !(item.purchaseOrderNumber === purchaseOrderNumber && item.skuCode === skuCode)
                    )
                );
            } else {
                toast.error('Failed to approve request');
            }
        } catch (err) {
            console.error('Error approving request:', err);
            toast.error('Error approving request');
        } finally {
            setLoading(false);
        }
    };

    const handleReject = (purchaseOrderNumber: string, skuCode: string) => {
        toast.success('Request rejected successfully');
        setData(prevData => 
            prevData.filter(item => 
                !(item.purchaseOrderNumber === purchaseOrderNumber && item.skuCode === skuCode)
            )
        );
    };

    const handleDownload = async (skuCode: string) => {
        try {
            console.log(`Downloading SKU: ${skuCode}`);
        } catch (err) {
            console.error('Error downloading QR code:', err);
        }
    };

    const baseColumns: ColumnConfig[] = [
        { 
            field: 'purchaseOrderNumber', 
            headerName: 'P0 Number', 
            width: 110 
        },
        { 
            field: 'purchaseOrderDate', 
            headerName: 'PO Date', 
            width: 110 
        },
        { 
            field: 'vendorName', 
            headerName: 'Vendor Name', 
            width: 140 
        },
        { 
            field: 'skuCode', 
            headerName: 'SKU Code', 
            width: 155 
        },
        { 
            field: 'skuName', 
            headerName: 'SKU Name', 
            width: 277 
        },
        { 
            field: 'generationDate', 
            headerName: 'Generation Date', 
            width: 195 
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            renderCell: () => (
                <Typography variant="body2" sx={{ marginTop: '15px'}}>
                    Downloaded
                </Typography>
            ),
        }
        
    ];

    const adminColumns: ColumnConfig[] = [
        ...baseColumns,
        {
            field: 'action',
            headerName: 'Action',
            width: 120,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    className="download-button"
                    onClick={() => handleDownload(params.row.skuCode)}
                >
                    Download
                </Button>
            ),
        },
        {
            field: 'requestAccess',
            headerName: 'Request Access',
            width: 250,
            renderCell: (params) => {
                return params.row.requestRaised === "true" && (
                    <div style={{ display: 'flex', gap: '8px' }}>
                        <Button
                            variant="contained"
                            className="approve-button"
                            startIcon={<CheckCircle size={16} />}
                            onClick={() => handleApprove(params.row.purchaseOrderNumber, params.row.skuCode)}
                        >
                            Approve
                        </Button>
                        <Button
                            variant="contained"
                            className="reject-button"
                            startIcon={<XCircle size={16} />}
                            onClick={() => handleReject(params.row.purchaseOrderNumber, params.row.skuCode)}
                        >
                            Reject
                        </Button>
                    </div>
                );
            },
        }
    ];

    const vendorColumns: ColumnConfig[] = [
        ...baseColumns,
        {
            field: 'requestAccess',
            headerName: 'Request Access',
            width: 250,
            renderCell: (params) => {
                if (params.row.requestStatus === 'pending') {
                    return (
                        <Typography variant="body2" color="primary">
                            Request Pending
                        </Typography>
                    );
                }
                return (
                    <Button
                        variant="contained"
                        className="request-access-button"
                        onClick={() => handleRequestAccess(params.row.purchaseOrderNumber, params.row.skuCode)}
                        disabled={params.row.requestStatus === 'pending'}
                    >
                        Request Access
                    </Button>
                );
            },
        }
    ];

    const columns = userType === 'admin' ? adminColumns : vendorColumns;

    if (error) {
        return (
            <div className="screen">
                <Typography color="error">
                    {error}
                </Typography>
            </div>
        );
    }

    return (
        <div className="screen">
            <Header />
            <ToastContainer  
            position="top-center"
            autoClose={4000} />
            <Typography variant="h6">
                QR History
            </Typography>
            <br />
            <div className="table">
                <CustomTable
                    editMode="row"
                    data={data}
                    columns={columns}
                    loading={loading}
                    getRowId={(row) =>{
                        console.log(row.id)
                        return row.id
                    }
                }
                />
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </div>
    );
};

export default QRHistory;
import React from 'react';
import './QR-Code-QC-Overview.css';
// Replace these with your actual SVG icon imports
import YourLocalIcon from '../../../assets/icons/qr_code.svg'; 
import CompletedIcon from '../../../assets/icons/completed.svg';
import RejectedIcon from '../../../assets/icons/rejected.svg';
import PendingIcon from '../../../assets/icons/pending.svg';

interface StatusCardProps {
  icon: string;
  color: string;
  value: string;
  label: string;
  percentage: number;
}

const StatusCard: React.FC<StatusCardProps> = ({ icon, color, value, label, percentage }) => {
  return (
    <div className="status-card">
      <div className="status-icon" style={{ backgroundColor: color }}>
        <img src={icon} alt={label} />
      </div>
      <div className="status-content">
        <div className="value-container">
          <h3>{value}</h3>
          {/* <span className="status-percentage" style={{ color: '#0084FF' }}>{percentage}%</span> */}
        </div>
        <p className="status-label">{label}</p>
      </div>
    </div>
  );
};

const QRCodeQCOverview: React.FC = () => {
  return (
    <div className="qr-qc-container">
      <div className="total-qr-card" style={{ backgroundColor: '#F2F3FF' }}>
        <div className="card-content">
          <div className="icon-container">
            <img src={YourLocalIcon} alt="Quality Control Icon" />
          </div>
          <div className="text-content">
            <h2>2,00,500</h2>
            <p>Total QR code for quality control</p>
            <div className="progress-bar">
              <div className="progress-fill"></div>
            </div>
            {/* <p className="percentage-change">
              <span className="arrow">↑</span>
              <span className="percentage">24%</span> From Yesterday
            </p> */}
          </div>
        </div>
      </div>
      
      <div className="status-cards">
        <StatusCard
          icon={CompletedIcon}  // Use the completed icon
          color="#4ADE80"
          value="1,00,000"
          label="Completed"
          percentage={24}
        />
        <StatusCard
          icon={RejectedIcon}  // Use the rejected icon
          color="#FF6B6B"
          value="1,00,000"
          label="Rejected"
          percentage={24}
        />
        <StatusCard
          icon={PendingIcon}  // Use the pending icon
          color="#FF9F43"
          value="500"
          label="Pending"
          percentage={24}
        />
      </div>
    </div>
  );
};

export default QRCodeQCOverview;

import React from "react";
import { Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PaletteColorOptions } from "@mui/material/styles";

// Import pages
import Login from "../pages/auth/Login";
import Dashboard from "../pages/dashboard/Dashboard";
import PurchaseOrder from "../pages/purchase_order/PurchaseOrder";
import PurchaseOrderDetails from "../pages/purchase_order/PurchaseOrderDetails";
import AboutUs from "../pages/home/AboutUs";
import ContactUs from "../pages/home/ContactUs";
import QRHistory from "../pages/home/QRHistory";
import Reports from "../pages/reports/Reports";
import UserManagement from "../pages/user_management/UserManagement";
import CreateUser from "../pages/user_management/CreateUser";
import SecuritySettings from "../pages/security_settings/SecuritySettings";
import QRGeneratedMonthWise from "../pages/reports/components/QR-Generated-Month-Wise";
import SKUWiseQRGeneratedTillDate from "../pages/reports/components/SKU-Wise-Generated-Till-Date";
import MonthwiseReportIndividualSKU from "../pages/reports/components/Monthwise-Individual-SKU";
import VendorwiseQRCodeCount from "../pages/reports/components/Vendorwise-QR-Count";
import POReceivedMonthWise from "../pages/reports/components/PO-Received-Month-Wise";
import VendorWisePO from "../pages/reports/components/Vendor-Wise-PO";

import LogsPage from "../pages/security_settings/Logs";
import QrGenerationLogs from "../pages/security_settings/system_logs/QrGenerationLogs";
import PoUploadLogs from "../pages/security_settings/system_logs/PoUploadLogs";
import AccessRequestLogs from "../pages/security_settings/system_logs/AccessRequestLogs";

// Import PrivateRoute for protected routes
import PrivateRoute from "../utils/PrivateRoute";
import PublicRoute from "../utils/PublicRoute";

// Define the primary and secondary color palette for the Material UI theme
const secondaryColorOptions: PaletteColorOptions = {
  main: "#731916",
};

const primaryColorOptions: PaletteColorOptions = {
  main: "#ED1B3B",
};

// Create a Material UI theme using the defined colors
const theme = createTheme({
  palette: {
    primary: primaryColorOptions,
    secondary: secondaryColorOptions,
  },
});

const AppRoutes = () => {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {/* Public route for login */}
        <Route path="/" element={<PublicRoute element={Login} />} />

        {/* Private routes */}
        <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
        <Route path="/purchaseorder" element={<PrivateRoute element={PurchaseOrder} />} />
        <Route path="/purchase-order-details/:id" element={<PrivateRoute element={PurchaseOrderDetails} />} />
        <Route path="/reports" element={<PrivateRoute element={Reports} />} />
        <Route path="/user-management" element={<PrivateRoute element={UserManagement} />} />
        <Route path="/create-user" element={<PrivateRoute element={CreateUser} />} />
        <Route path="/contactus" element={<PrivateRoute element={ContactUs} />} />
        <Route path="/aboutus" element={<PrivateRoute element={AboutUs} />} />
        <Route path="/qr-history/:itemCode" element={<PrivateRoute element={QRHistory} />} />
        <Route path="/qr-history" element={<PrivateRoute element={QRHistory} />} />
        <Route path="/security-settings" element={<PrivateRoute element={SecuritySettings} />} />

        <Route path="/logs" element={<PrivateRoute element={LogsPage} />} /> 
        <Route path="/qr-generation-logs" element={<PrivateRoute element={QrGenerationLogs} />} />
        <Route path="/po-upload-logs" element={<PrivateRoute element={PoUploadLogs} />} />
        <Route path="/access-request-logs" element={<PrivateRoute element={AccessRequestLogs} />} />

        <Route path="/qr-generated-month-wise" element={<PrivateRoute element={QRGeneratedMonthWise} />} />
        <Route path="/sku-wise-generated-till-date" element={<PrivateRoute element={SKUWiseQRGeneratedTillDate} />} />
        <Route path="/monthwise-report-for-individual-sku" element={<PrivateRoute element={MonthwiseReportIndividualSKU} />} />
        <Route path="/vendorwise-qr-code-count" element={<PrivateRoute element={VendorwiseQRCodeCount} />} />
        <Route path="/po-received-month-wise" element={<PrivateRoute element={POReceivedMonthWise} />} />
        <Route path="/vendor-wise-po" element={<PrivateRoute element={VendorWisePO} />} />
      </Routes>
    </ThemeProvider>
  );
};

export default AppRoutes;

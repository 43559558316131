import React, { createContext, useContext, useState, useEffect } from "react";


interface GeoLocation {
  latitude: number | null;
  longitude: number | null;
}

const GeoContext = createContext<GeoLocation>({
  latitude: null,
  longitude: null,
});

export const GeoProvider = ({ children }: { children: React.ReactNode }) => {
  const [location, setLocation] = useState<GeoLocation>({
    latitude: null,
    longitude: null,
  });
  let debounceTimeout: NodeJS.Timeout;

  useEffect(() => {
    const handleGeoChange = () => {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissions) => {
          if (
            permissions.state === "granted" ||
            permissions.state === "prompt"
          ) {
            navigator.geolocation.getCurrentPosition((pos) => {
              if (debounceTimeout) {
                clearTimeout(debounceTimeout);
              }
  
              // Set a new timeout to update the location after 500ms (debouncing)
              debounceTimeout = setTimeout(() => {
                const newLocation = {
                  latitude: pos.coords.latitude,
                  longitude: pos.coords.longitude,
                };
                setLocation(newLocation);
  
                // Store geolocation in sessionStorage for global access
                sessionStorage.setItem("latitude", newLocation.latitude.toString());
                sessionStorage.setItem("longitude", newLocation.longitude.toString());
              }, 500); // Delay of 500ms before updating location
            });
          }
        });
    };
  
    // Initial geolocation fetch
    handleGeoChange();
  
    // Set an interval to check geolocation every 5 seconds
    const intervalId = setInterval(handleGeoChange, 5000);
  
    // Cleanup the interval and timeout when the component unmounts
    return () => {
      clearInterval(intervalId);
      clearTimeout(debounceTimeout);
    };
  }, []);

  return <GeoContext.Provider value={location}>{children}</GeoContext.Provider>;
};

export const useGeoContext = () => {
  return useContext(GeoContext);
};

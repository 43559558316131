import React, { useState, useEffect } from 'react';
import { Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, InputLabel, FormControl, Grid, Box } from '@mui/material';
import CustomTable, { ColumnConfig } from "../../../components/CustomTable";
import Header from '../../dashboard/components/Header';
import BackButton from '../../../components/BackButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import excelIcon from '../../../assets/excel.png';
import { SelectChangeEvent } from '@mui/material';
import { getVendorWisePOSummary, fetchPurchaseOrderNumbers, getItemCodes, fetchUsers } from '../../../utils/services/apiService';
import { GridCellParams } from '@mui/x-data-grid';

interface VendorPOData {
    qr: string;
    month: string;
    item_code: string;
    category: string;
    sub_category: string;
    scan_eligibility: string;
    vendor_full_name: string;
    purchase_order_number: string;
    qr_status: string;
    created_at: string;
}

interface Vendor {
    user_id: number;
    user_full_name: string;
    user_type: string;
}

const VendorWisePO: React.FC = () => {
    const [data, setData] = useState<VendorPOData[]>([]);
    const [loading, setLoading] = useState(true);
    const [exportStatus, setExportStatus] = useState<string | null>(null);
    const [openFilterDialog, setOpenFilterDialog] = useState(false);
    const [skuList, setSkuList] = useState<string[]>([]);
    const [vendors, setVendors] = useState<Vendor[]>([]);
    const [purchaseOrders, setPurchaseOrders] = useState<string[]>([]);
    const [userType, setUserType] = useState<string>('');
    const [filter, setFilter] = useState({
        month: '',
        year: '',
        sku: '',
        poNumber: '',
        vendorId: ''
    });

    useEffect(() => {
        // Get user type from session storage
        const userTypeFromSession = sessionStorage.getItem('userType') || '';
        setUserType(userTypeFromSession.toLowerCase());

        // If user is a vendor, set their ID from session storage
        if (userTypeFromSession.toLowerCase() === 'vendor') {
            const vendorIdFromSession = sessionStorage.getItem('userId') || '';
            setFilter(prev => ({
                ...prev,
                vendorId: vendorIdFromSession
            }));
        }

        loadVendorPOData();
        loadSkuList();
        loadVendors();
        loadPurchaseOrders();
    }, []);

    const loadSkuList = async () => {
        try {
            const response = await getItemCodes();
            if (response.data && response.status === 200) {
                setSkuList(response.data.data || []);
            }
        } catch (error) {
            console.error('Error loading SKU list:', error);
        }
    };

    const loadVendors = async () => {
        try {
            const response = await fetchUsers();
            if (response.data && response.status === 200) {
                const vendorsList = response.data.data.filter(
                    (user: Vendor) => user.user_type.toLowerCase() === 'vendor'
                );
                setVendors(vendorsList);
            }
        } catch (error) {
            console.error('Error loading vendors:', error);
        }
    };

    const loadPurchaseOrders = async () => {
        try {
            const response = await fetchPurchaseOrderNumbers();
            if (response.data && response.status === 200) {
                setPurchaseOrders(response.data.purchaseOrdersItemDetails.purchaseOrders || []);
            }
        } catch (error) {
            console.error('Error loading purchase orders:', error);
        }
    };

    const loadVendorPOData = async () => {
        setLoading(true);
        try {
            // If user is a vendor, always use their ID
            const effectiveVendorId = userType === 'vendor'
                ? parseInt(sessionStorage.getItem('userId') || '0')
                : (filter.vendorId ? parseInt(filter.vendorId) : undefined);

            const response = await getVendorWisePOSummary({
                year: filter.year ? parseInt(filter.year) : undefined,
                month: filter.month ? parseInt(filter.month) : undefined,
                sku: filter.sku || undefined,
                purchase_order_number: filter.poNumber || undefined,
                vendor_id: effectiveVendorId,
                exportExcel: false
            });
            if (response.data && response.data.purchaseOrdersItemDetails) {
                setData(response.data.purchaseOrdersItemDetails);
            }
        } catch (error) {
            console.error('Error loading vendor PO data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleExport = async () => {
        setExportStatus('Exporting data...');
        try {
            // If user is a vendor, always use their ID
            const effectiveVendorId = userType === 'vendor'
                ? parseInt(sessionStorage.getItem('userId') || '0')
                : (filter.vendorId ? parseInt(filter.vendorId) : undefined);

            const response = await getVendorWisePOSummary({
                year: filter.year ? parseInt(filter.year) : undefined,
                month: filter.month ? parseInt(filter.month) : undefined,
                sku: filter.sku || undefined,
                purchase_order_number: filter.poNumber || undefined,
                vendor_id: effectiveVendorId,
                exportExcel: true
            });

            const blob = new Blob([response.data], { 
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `vendor-po-summary-${new Date().toISOString()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            setExportStatus('Export successful! The data has been downloaded.');
            setTimeout(() => setExportStatus(null), 3000);
        } catch (error) {
            console.error('Error exporting data:', error);
            setExportStatus('Export failed. Please try again.');
            setTimeout(() => setExportStatus(null), 3000);
        }
    };

    const handleOpenFilterDialog = () => {
        setOpenFilterDialog(true);
    };

    const handleCloseFilterDialog = () => {
        setOpenFilterDialog(false);
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
        const { name, value } = event.target;
        setFilter({
            ...filter,
            [name]: value
        });
    };

    const handleApplyFilter = () => {
        loadVendorPOData();
        handleCloseFilterDialog();
    };

    const months = [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' }
    ];

    const years = ['2024', '2023', '2022', '2021'];

    const columns: ColumnConfig[] = [
        { field: 'qr', headerName: 'QR Code', width: 280 },
        { field: 'month', headerName: 'Month', width: 120 },
        { field: 'item_code', headerName: 'Item Code', width: 150 },
        { field: 'category', headerName: 'Category', width: 150 },
        { field: 'sub_category', headerName: 'Sub Category', width: 150 },
        { field: 'scan_eligibility', headerName: 'Scan Eligibility', width: 150 },
        { field: 'vendor_full_name', headerName: 'Vendor Name', width: 150 },
        { field: 'purchase_order_number', headerName: 'PO Number', width: 150 },
        { field: 'qr_status', headerName: 'QR Status', width: 120 },
        { 
            field: 'created_at', 
            headerName: 'Created At', 
            width: 220,
            renderCell: (params: GridCellParams) => {
                const value = params.value as string | number;
                return value ? new Date(value).toLocaleString() : '';
            }
        }
    ].map(col => ({
        ...col,
        renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
        renderCell: (params) => <div className="cell-center">{params.value}</div>
    }));

    return (
        <div className="screen">
            <Header />
            <div className="table">
                <div className="table-header-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box display="flex" alignItems="center" gap={2}>
                <BackButton redirectTo="/reports" />
                    <Typography variant="h6" className="breadcrumb">
                    Vendor Wise PO
                    </Typography>
                    </Box>
                    <Box display="flex" gap={2}>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: 'whitesmoke', color: '#007d3c', fontWeight: 'bold', border: '1px solid #ccc' }}
                            onClick={handleExport}
                            className="export-button"
                        >
                            <img src={excelIcon} alt="Excel Icon" style={{ width: 20, height: 20, marginRight: 8 }} />
                            Export
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenFilterDialog}
                            className="filter-button"
                        >
                            <FilterAltIcon style={{ color: 'white' }} />
                            Filter
                        </Button>
                    </Box>
                </div>

                {exportStatus && (
                    <Typography variant="body2" className="export-status" style={{ marginTop: 16 }}>
                        {exportStatus}
                    </Typography>
                )}

                <div className="custom-table">
                    <CustomTable
                        editMode="row"
                        data={data}
                        columns={columns}
                        loading={loading}
                        getRowId={(row) => row.qr}
                    />
                </div>
            </div>

            <Dialog
                open={openFilterDialog}
                onClose={handleCloseFilterDialog}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Apply Filters</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Month</InputLabel>
                                <Select
                                    name="month"
                                    value={filter.month}
                                    onChange={handleFilterChange}
                                    label="Month"
                                >
                                    <MenuItem value="">All Months</MenuItem>
                                    {months.map((month) => (
                                        <MenuItem key={month.value} value={month.value}>
                                            {month.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Year</InputLabel>
                                <Select
                                    name="year"
                                    value={filter.year}
                                    onChange={handleFilterChange}
                                    label="Year"
                                >
                                    <MenuItem value="">All Years</MenuItem>
                                    {years.map((year) => (
                                        <MenuItem key={year} value={year}>{year}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>SKU</InputLabel>
                                <Select
                                    name="sku"
                                    value={filter.sku}
                                    onChange={handleFilterChange}
                                    label="SKU"
                                >
                                    <MenuItem value="">All SKUs</MenuItem>
                                    {skuList.map((sku) => (
                                        <MenuItem key={sku} value={sku}>{sku}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>PO Number</InputLabel>
                                <Select
                                    name="poNumber"
                                    value={filter.poNumber}
                                    onChange={handleFilterChange}
                                    label="PO Number"
                                >
                                    <MenuItem value="">All POs</MenuItem>
                                    {purchaseOrders.map((po) => (
                                        <MenuItem key={po} value={po}>{po}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Show vendor field only for admin and analytics users */}
                        {(userType === 'admin' || userType === 'analytics') && (
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Vendor</InputLabel>
                                    <Select
                                        name="vendorId"
                                        value={filter.vendorId}
                                        onChange={handleFilterChange}
                                        label="Vendor"
                                    >
                                        <MenuItem value="">All Vendors</MenuItem>
                                        {vendors.map((vendor) => (
                                            <MenuItem key={vendor.user_id} value={vendor.user_id}>
                                                {vendor.user_full_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFilterDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleApplyFilter} color="primary">
                        Apply Filter
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default VendorWisePO;
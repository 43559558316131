import React, { useCallback } from 'react';
import {
    DataGrid,
    GridRowModel,
    GridColDef,
    GridRowId,
    GridRowsProp,
    GridValidRowModel,
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Alert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

interface DataItem {
    [key: string]: any;
}

export type ColumnConfig = GridColDef<DataItem>;

interface CustomTableProps {
    data: DataItem[];
    columns: ColumnConfig[];
    loading: boolean;
    editMode: 'row' | 'cell';
    getRowId: (row: DataItem) => GridRowId;
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-row:focus, & .MuiDataGrid-row:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
}));

const useFakeMutation = () => {
    return useCallback(
        (newRow: GridRowModel) =>
            new Promise<GridRowModel>((resolve, reject) => {
                setTimeout(() => {
                    const invalidColumn = Object.keys(newRow).find(
                        (key) => key !== 'id' && key !== '__typename' && newRow[key] === ''
                    );
                    if (invalidColumn) {
                        reject(invalidColumn);
                    } else {
                        resolve(newRow);
                    }
                }, 200);
            }),
        []
    );
};

function computeMutation(newRow: GridRowModel, oldRow: GridRowModel) {
    const changedColumns = Object.keys(newRow).filter(
        (key) => newRow[key] !== oldRow[key] && key !== 'id' && key !== '__typename'
    );
    if (changedColumns.length === 0) {
        return null;
    }
    return `Changed ${changedColumns.join(', ')} from '${changedColumns.map((col) => oldRow[col]).join(', ')}' to '${changedColumns.map((col) => newRow[col]).join(', ')}'`;
}

const CustomTable: React.FC<CustomTableProps> = ({
    data,
    columns,
    loading = true,
    getRowId,
    editMode = 'cell',
}) => {
    const mutateRow = useFakeMutation();

    const noButtonRef = React.useRef<HTMLButtonElement>(null);
    const [promiseArguments, setPromiseArguments] = React.useState<any>(null);

    const [snackbar, setSnackbar] = React.useState<Pick<
        AlertProps,
        'children' | 'severity'
    > | null>(null);

    const handleCloseSnackbar = () => setSnackbar(null);

    const processRowUpdate = useCallback(
        (newRow: GridValidRowModel, oldRow: GridValidRowModel) =>
            new Promise<GridValidRowModel>((resolve, reject) => {
                const mutation = computeMutation(newRow, oldRow);
                if (mutation) {
                    setPromiseArguments({ resolve, reject, newRow, oldRow });
                } else {
                    resolve(oldRow);
                }
            }),
        [],
    );

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: 'none',
        },
        '& .MuiDataGrid-row:focus, & .MuiDataGrid-row:focus-within': {
            outline: 'none',
        },
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
        },
        // Make all header cells bold
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
        },
    }));
    

    const handleNo = () => {
        const { oldRow, resolve } = promiseArguments;
        resolve(oldRow);
        setPromiseArguments(null);
    };

    const handleYes = async () => {
        const { newRow, reject, resolve } = promiseArguments;

        try {
            const response = await mutateRow(newRow);
            setSnackbar({ children: 'User successfully saved', severity: 'success' });
            resolve(response);
            setPromiseArguments(null);
        } catch (error) {
            const changedColumn = error as string;
            setSnackbar({ children: `${changedColumn} cannot be empty`, severity: 'error' });
            reject(newRow);
            setPromiseArguments(null);
        }
    };

    const handleEntered = () => {
        // This function is called when the dialog animation is finished
        // You can add any logic here if needed
    };

    const renderConfirmDialog = () => {
        if (!promiseArguments) {
            return null;
        }

        const { newRow, oldRow } = promiseArguments;
        const mutation = computeMutation(newRow, oldRow);

        return (
            <Dialog
                maxWidth="xs"
                TransitionProps={{ onEntered: handleEntered }}
                open={!!promiseArguments}
            >
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent dividers>
                    {`Pressing 'Yes' will change ${mutation}.`}
                </DialogContent>
                <DialogActions>
                    <Button ref={noButtonRef} onClick={handleNo}>
                        No
                    </Button>
                    <Button onClick={handleYes}>Yes</Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <div>
            <div style={{ width: '100%' }}>
                {renderConfirmDialog()}
                <StyledDataGrid
    editMode={editMode}
    rows={data}
    columns={columns}
    loading={loading}
    autoHeight={true}
    getRowId={getRowId}
    initialState={{
        pagination: {
            paginationModel: {
                pageSize: 12, // Change this to 7
            },
        },
    }}
    pageSizeOptions={[15, 25, 50, 100]} // Update this as well if needed
    processRowUpdate={processRowUpdate}
/>
                {!!snackbar && (
                    <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
            </div>
        </div>
    );
};

export default CustomTable;
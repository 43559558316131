import React, { useEffect, useState } from 'react';
import { getQrCountByStatuses } from '../../../utils/services/apiService';
import './Old-QR-Code-Summary.css';

interface QrCountResponse {
  status: number;
  message: string;
  data: {
    CountOfPurchaseOrders: {
      activeCounts: { month: string; count: number }[];
      inactiveCounts: { month: string; count: number }[];
    };
  };
}

const QRCodeGaugeChart: React.FC = () => {
  const [totalCodes, setTotalCodes] = useState(0);
  const [activeCodes, setActiveCodes] = useState(0);
  const [inactiveCodes, setInactiveCodes] = useState(0);
  const [date, setDate] = useState('');

  useEffect(() => {
    const fetchQrCountData = async () => {
      try {
        const currentYear = new Date().getFullYear();
        const response = await getQrCountByStatuses(currentYear);
        if (response.status === 200) {
          const { data } = response.data as QrCountResponse;
          const { activeCounts, inactiveCounts } = data.CountOfPurchaseOrders;

          // Calculate total codes
          const totalActiveCounts = activeCounts.reduce((sum, item) => sum + item.count, 0);
          const totalInactiveCounts = inactiveCounts.reduce((sum, item) => sum + item.count, 0);
          const totalCodes = totalActiveCounts + totalInactiveCounts;

          // Get the current date
          const currentDate = new Date();
          const formattedDate = currentDate.toLocaleDateString();

          setTotalCodes(totalCodes);
          setActiveCodes(totalActiveCounts);
          setInactiveCodes(totalInactiveCounts);
          setDate(formattedDate);
        }
      } catch (error) {
        console.error('Error fetching QR count data:', error);
      }
    };

    fetchQrCountData();
  }, []);

  return (
    <div className="qr-code-gauge-chart">
      <div className="header">
        <div className="icon">
          <svg width="60" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 3H10V10H3V3Z"
              stroke="#FF1493"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 3H21V10H14V3Z"
              stroke="#FF1493"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 14H10V21H3V14Z"
              stroke="#FF1493"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 14H21V21H14V14Z"
              stroke="#FF1493"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <h2>QR Code Summary Report</h2>
      </div>
      <div className="total-codes">
        <span className="number">{totalCodes.toLocaleString()}</span>
        <span className="label">Total QR Codes inserted on</span>
        <span className="date">{date}</span>
      </div>
      <div className="gauge-container">
        <svg width="360" height="250" viewBox="0 0 500 300">
          {/* Left side (Pink) */}
          <path
            d="M50 250 A200 200 0 0 1 250 50"
            fill="none"
            stroke="#FF1493"
            strokeWidth="75"
          />
          {/* Right side (Yellow) */}
          <path
            d="M250 50 A200 200 0 0 1 450 250"
            fill="none"
            stroke="#FFA500"
            strokeWidth="65"
          />
        </svg>
        {/* Gauge number labels */}
        <div className="gauge-number-labels">
          <span className="gauge-number active-number">{activeCodes.toLocaleString()} Active</span>
          <span className="gauge-number inactive-number">{inactiveCodes.toLocaleString()} Inactive</span>
        </div>
      </div>
      {/* Legend */}
      <div className="legend">
        <div className="legend-item">
          <span className="dot active"></span>
          <span className="label">Active</span>
        </div>
        <div className="legend-item">
          <span className="dot inactive"></span>
          <span className="label">Inactive</span>
        </div>
      </div>
    </div>
  );
};

export default QRCodeGaugeChart;
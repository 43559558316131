import React, { FC, useEffect, useCallback } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { AuthProvider } from "./utils/AuthContext";
import ForbiddenPopup from "./components/ForbiddenPopup";
import { ErrorProvider } from "./components/ErrorContext";
import { GeoProvider } from "./components/GeoContext";

const App: FC = () => {
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos) {
    var crd = pos.coords;
    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

useEffect(() => {
    navigator.permissions.query({name:'geolocation'}).then((permissions) => { 
      if (permissions.state === "granted") {
        navigator.geolocation.getCurrentPosition((geocode)=>{
          let data = geocode.coords;
          console.log(data);
        },(err)=>{
          console.log(`Error: ${err.message}`);
        },{
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        })
      } else if (permissions.state === "prompt") {
        navigator.geolocation.getCurrentPosition((geocode)=>{
          console.log(`Current position is: ${JSON.parse(JSON.stringify(geocode.coords))}`);
        },(err)=>{
          console.log(`Error: ${err.message}`);
        },{
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        })
      } else if (permissions.state === "denied") {
        console.log("Geolocation denied");
      }

      console.log(permissions); })
    .catch(err => {
       console.log(err); }
      );
  }, []);

  return (
    <AuthProvider>
           
    <ErrorProvider>
    <GeoProvider>
      <ForbiddenPopup/>
      <Router>
        <AppRoutes />
      </Router>
      </GeoProvider>
    </ErrorProvider>
    
    </AuthProvider>
  );
};

export default App;
import React, { useState, useEffect } from 'react';
import { Typography, Button, Select, MenuItem, FormControl, InputLabel, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CustomTable, { ColumnConfig } from "../../../components/CustomTable";
import Header from '../../dashboard/components/Header';
import BackButton from '../../../components/BackButton';
import excelIcon from '../../../assets/excel.png';

interface PurchaseOrderReceivedItem {
    po_number: string;
    supplier_name: string;
    received_date: string;
    item_code: string;
    quantity_received: number;
    total_cost: string;
}

const POReceivedMonthWise: React.FC = () => {
    const [data, setData] = useState<PurchaseOrderReceivedItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [exportStatus, setExportStatus] = useState<string | null>(null);
    const [isFilterDialogOpen, setFilterDialogOpen] = useState(false);
    const [year, setYear] = useState('');
    const [supplierId, setSupplierId] = useState('');
    const userType = sessionStorage.getItem('userType');
    const supplierIds = ['S001', 'S002', 'S003'];

    useEffect(() => {
        loadPurchaseOrderData();
    }, []);

    const loadPurchaseOrderData = () => {
        setLoading(true);
        const dummyData: PurchaseOrderReceivedItem[] = [
            {
                po_number: "PO12345",
                supplier_name: "Supplier A",
                received_date: "2024-10-15",
                item_code: "20BB1001",
                quantity_received: 100,
                total_cost: "500"
            },
            {
                po_number: "PO12346",
                supplier_name: "Supplier B",
                received_date: "2024-10-20",
                item_code: "20BB1002",
                quantity_received: 150,
                total_cost: "750"
            },
            {
                po_number: "PO12347",
                supplier_name: "Supplier C",
                received_date: "2024-10-25",
                item_code: "20BB1003",
                quantity_received: 200,
                total_cost: "1000"
            }
        ];
        setData(dummyData);
        setLoading(false);
    };

    const handleExport = () => {
        setExportStatus('Exporting data...');
        setTimeout(() => {
            setExportStatus('Export successful! The data has been downloaded.');
            setTimeout(() => setExportStatus(null), 3000);
        }, 2000);
    };

    const handleFilterDialogOpen = () => setFilterDialogOpen(true);
    const handleFilterDialogClose = () => setFilterDialogOpen(false);

    const columns: ColumnConfig[] = [
        { field: 'po_number', headerName: 'PO Number', width: 150 },
        { field: 'supplier_name', headerName: 'Supplier Name', width: 200 },
        { field: 'received_date', headerName: 'Received Date', width: 150 },
        { field: 'item_code', headerName: 'Item Code', width: 150 },
        { field: 'quantity_received', headerName: 'Quantity Received', width: 150 },
        { field: 'total_cost', headerName: 'Total Cost', width: 150 },
    ];

    return (
        <div className="screen">
            <Header />
            <div className="table">
                <div className="table-header-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box display="flex" alignItems="center" gap={2}>
                <BackButton redirectTo="/reports" />
                    <Typography variant="h6" className="breadcrumb">
                    PO Received Month Wise
                    </Typography>
                    </Box>
                    <Box display="flex" gap={2}>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: 'whitesmoke', color: '#007d3c', fontWeight: 'bold', border: '1px solid #ccc' }}
                            onClick={handleExport}
                            className="export-button"
                        >
                            <img src={excelIcon} alt="Excel Icon" style={{ width: 20, height: 20, marginRight: 8 }} />
                            Export
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFilterDialogOpen}
                            className="filter-button"
                        >
                            <FilterAltIcon style={{ color: 'white' }} />
                            Filter
                        </Button>
                    </Box>
                </div>

                {exportStatus && (
                    <Typography variant="body2" className="export-status" style={{ marginTop: 16 }}>
                        {exportStatus}
                    </Typography>
                )}

                <div className="custom-table">
                    <CustomTable
                        editMode="row"
                        data={data}
                        columns={columns}
                        loading={loading}
                        getRowId={(data: any) => data.po_number}
                    />
                </div>

                <Dialog open={isFilterDialogOpen} onClose={handleFilterDialogClose} maxWidth="sm" fullWidth>
                    <DialogTitle>Filter Purchase Order Data</DialogTitle>
                    <DialogContent>
                        <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel>Year</InputLabel>
                            <Select
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                                label="Year"
                            >
                                <MenuItem value="2023">2023</MenuItem>
                                <MenuItem value="2024">2024</MenuItem>
                            </Select>
                        </FormControl>
                        {userType === 'admin' && (
                            <FormControl variant="outlined" fullWidth margin="normal">
                                <InputLabel>Supplier ID</InputLabel>
                                <Select
                                    value={supplierId}
                                    onChange={(e) => setSupplierId(e.target.value)}
                                    label="Supplier ID"
                                >
                                    {supplierIds.map((id) => (
                                        <MenuItem key={id} value={id}>
                                            {id}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleFilterDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleFilterDialogClose} color="primary" variant="contained">
                            Apply Filter
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
};

export default POReceivedMonthWise;

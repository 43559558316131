import React, { useState, useEffect } from 'react';
import './QR-Code-Count-Chart.css';
import QRCodeIcon from '../../../assets/icons/Vendor-QR.svg';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { getTotalNumberOfQrsGeneratedYearWiseForAVendor, getUserList } from '../../../utils/services/apiService';

interface QRCodeData {
  month: string;
  count: number;
}

interface User {
  user_id: number;
  user_full_name: string;
}

const QRCodeCountChart: React.FC = () => {
  const [qrCodeData, setQrCodeData] = useState<QRCodeData[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<number>(2); // default user
  const [showUserList, setShowUserList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [tooltip, setTooltip] = useState<{
    visible: boolean;
    x: number;
    y: number;
    month: string;
    count: number;
  } | null>(null);

  // Get userType from sessionStorage or any other global state
  const userType = sessionStorage.getItem('userType'); // assuming userType is stored in sessionStorage

  useEffect(() => {
    fetchUserList();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      fetchVendorData(selectedUser, selectedYear);
    }
  }, [selectedUser, selectedYear]);

  // Fetch the user list and set the default vendor if user is Admin or Analytics
  const fetchUserList = async () => {
    try {
      const response = await getUserList();
      if (response.status === 200 && response.data?.data) {
        const userList = response.data.data;
        setUsers(userList);

        // Select the topmost vendor if the user is Admin or Analytics
        if (userType === 'admin' || userType === 'analytics') {
          const defaultVendor = userList[0]?.user_id;
          if (defaultVendor) {
            setSelectedUser(defaultVendor);
          }
        } else {
          // Fallback to selectedUser logic for other user types
          if (!selectedUser && userList.length > 0) {
            setSelectedUser(userList[0].user_id);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching user list:', error);
    }
  };

  const fetchVendorData = async (userId: number, year: number) => {
    setIsLoading(true);
    try {
      const response = await getTotalNumberOfQrsGeneratedYearWiseForAVendor({
        user_id: userId,
        year,
      });

      if (response.status === 200) {
        const monthlyData = response.data?.data?.totalQrsGeneratedYearWiseByAVendor?.data?.skuWiseQrsGenerated || [];
        const formattedData = formatVendorData(monthlyData);
        setQrCodeData(formattedData);
      }
    } catch (error) {
      console.error('Error fetching vendor data:', error);
      setQrCodeData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const formatVendorData = (data: any[]): QRCodeData[] => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return data.map((item) => ({
      month: months[parseInt(item.month) - 1],
      count: parseInt(item.totalquantity) || 0,
    }));
  };

  const maxCount = Math.max(...qrCodeData.map((d) => d.count), 1);

  const handleMouseOver = (event: React.MouseEvent<HTMLDivElement>, month: string, count: number) => {
    const { clientX, clientY } = event;
    setTooltip({ visible: true, x: clientX, y: clientY, month, count });
  };

  const handleMouseLeave = () => {
    setTooltip(null);
  };

  const toggleUserList = () => {
    setShowUserList(!showUserList);
  };

  const selectUser = (userId: number) => {
    setSelectedUser(userId);
    setShowUserList(false);
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(parseInt(event.target.value));
  };

  return (
    <div className="qr-code-chart">
      <div className="chart-header">
        <div className="title-container">
          <div className="icon-container">
            <img src={QRCodeIcon} alt="QR Code Icon" width="24" height="24" />
          </div>
          <div>
            <h3>Vendorwise QR Code Count</h3>
            <p className="growth-text">Year {selectedYear}</p>
          </div>
        </div>
        <div className="filter-container">
          <FormControl sx={{ width: '85px', marginTop: '4px', marginRight: '10px' }}>
            <InputLabel>Year</InputLabel>
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(Number(e.target.value))}
              label="Year"
            >
              <MenuItem value={2022}>2022</MenuItem>
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2024}>2024</MenuItem>
            </Select>
          </FormControl>

          {/* Conditional rendering for filter button */}
          {userType === 'admin' || userType === 'analytics' ? (
            <button title="Filter" className="filter-button" onClick={toggleUserList}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 3H2l8 9.46V19l4 2v-8.54L22 3z" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          ) : null}

          {showUserList && users && users.length > 0 && (
            <div className="user-list-dropdown">
              {users.map((user) => (
                <div key={user.user_id} className={`user-list-item ${selectedUser === user.user_id ? 'selected' : ''}`} onClick={() => selectUser(user.user_id)}>
                  {user.user_full_name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="chart-content">
        {isLoading ? (
          <div className="loading">Loading...</div>
        ) : (
          <>
            {qrCodeData.map((item) => (
              <div key={item.month} className="chart-row">
                <span className="month-label">{item.month}</span>
                <div className="bar-container">
                  <div
                    className="bar"
                    style={{
                      width: `${(item.count / maxCount) * 100}%`,
                    }}
                    onMouseOver={(event) => handleMouseOver(event, item.month, item.count)}
                    onMouseLeave={handleMouseLeave}
                  />
                </div>
              </div>
            ))}
            <div className="x-axis">
              {Array.from({ length: 5 }, (_, i) => (
                <span key={i} className="x-axis-label">
                  {Math.ceil((maxCount * (i + 1)) / 5).toLocaleString()}
                </span>
              ))}
            </div>
          </>
        )}
      </div>

      {tooltip && tooltip.visible && (
        <div className="tooltip" style={{ left: tooltip.x + 10, top: tooltip.y - 40 }}>
          <span className="tooltip-count">{tooltip.count.toLocaleString()} QR Codes</span>
          <br />
          <span className="tooltip-month">{tooltip.month} {selectedYear}</span>
        </div>
      )}
    </div>
  );
};

export default QRCodeCountChart;

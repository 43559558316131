import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { getCountOfPurchaseOrders } from '../../../utils/services/apiService'; // Adjust the import path as needed
import { ReactComponent as PurchaseIcon } from '../../../assets/icons/order.svg'; // Adjust path based on your folder structure
import './Purchase-Orders-Chart.css';

// Register required components
ChartJS.register(ArcElement, Tooltip, Legend);

const PurchaseOrdersChart: React.FC = () => {
  const [purchaseOrderCount, setPurchaseOrderCount] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPurchaseOrderCount = async () => {
      try {
        const response = await getCountOfPurchaseOrders();
        if (response.status === 200 && response.data && response.data.data) {
          setPurchaseOrderCount(response.data.data.CountOfPurchaseOrders);
        } else {
          setError('Failed to fetch purchase order count');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPurchaseOrderCount();
  }, []);

  const data = {
    datasets: [
      {
        data: [purchaseOrderCount || 0, 100 - (purchaseOrderCount || 0)],
        backgroundColor: ['#00D2F2', '#E5E5E5'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: '75%',
    plugins: {
      tooltip: { enabled: false },
    },
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="purchase-orders-card">
      <div className="purchase-orders-header">
        <div className="header-icon">
          <PurchaseIcon width={30} height={30} />
        </div>
        <div>
          <h4>Total Number of Purchase Orders</h4>
          {/* <p className="order-percentage">+35% since yesterday</p> */}
        </div>
      </div>
      <div className="purchase-orders-chart">
        <Doughnut data={data} options={options} />
        <div className="chart-center-text">
          <h2>{purchaseOrderCount !== null ? purchaseOrderCount.toLocaleString() : 'N/A'}</h2>
          <p>Purchase orders have been received</p>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrdersChart;
import React from 'react';
import { Gauge } from '@mui/x-charts/Gauge';
import { styled } from '@mui/material/styles';
import './GRN-GaugChart.css';

const StyledGauge = styled(Gauge)(({ theme }) => ({
  width: '100%',
  height: 375,
  '& text': {
    display: 'none',  
  },
  '& .MuiCharts-gaugeArc': {
    strokeWidth: 12,
  },
}));

const GRNGaugeChart: React.FC = () => {
  const formattedValue = '12,424';

  return (
    <div className="grn-gauge-container">
      <div className="gauge-header">
        <div className="icon-wrapper">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
              stroke="#E91E63"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 7H17"
              stroke="#E91E63"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M7 12H17"
              stroke="#E91E63"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M7 17H17"
              stroke="#E91E63"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <h2>Total Number of GRN against PO</h2>
      </div>
      {/* <div className="percentage-increase">
        <span>+35% since yesterday</span>
      </div> */}
      <div className="gauge-chart">
        <StyledGauge
          value={82.8} // Keeps the value for the gauge arc percentage
          startAngle={-110}
          endAngle={110}
        />
        <div className="gauge-center-content">
          <div className="gauge-value">{formattedValue}</div>
          <div className="gauge-subtitle">GRN against Purchase Order</div>
        </div>
      </div>
    </div>
  );
};

export default GRNGaugeChart;

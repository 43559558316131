import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Reports.css';
import Header from '../../pages/dashboard/components/Header';

const Reports: React.FC = () => {
  const navigate = useNavigate();

  const handleView = (reportType: string) => {
    console.log(`Viewing report: ${reportType}`);
    if (reportType === 'QR_Codes_Generated_Month_Wise') {
      navigate('/qr-generated-month-wise');
    } else if (reportType === 'SKU_Wise_QR_Code_Generated_Till_Date') {
      navigate('/sku-wise-generated-till-date');
    } else if (reportType === 'Monthwise_Report_for_Individual_SKU') {
      navigate('/monthwise-report-for-individual-sku');
    } else if (reportType === 'Vendorwise_QR_Code_Count') {
      navigate('/vendorwise-qr-code-count');
    } else if (reportType === 'PO_Received_Month_Wise') {
      navigate('/po-received-month-wise');
    } else if (reportType === 'Vendor_Wise_PO') {
      navigate('/vendor-wise-po');
    }
  };

  return (
    <div>
      <Header />
      <div className="reports-container">
        <h2 className="reports-title">Reports</h2>
        
        <div className="report-section">
          <span className="report-name">QR Codes Generated Month Wise</span>
          <button className="view-report-btn" onClick={() => handleView('QR_Codes_Generated_Month_Wise')}>View Report</button>
        </div>

        <div className="report-section">
          <span className="report-name">SKU Wise QR Code Generated Till Date</span>
          <button className="view-report-btn" onClick={() => handleView('SKU_Wise_QR_Code_Generated_Till_Date')}>View Report</button>
        </div>

        <div className="report-section">
          <span className="report-name">Monthwise Report for Individual SKU</span>
          <button className="view-report-btn" onClick={() => handleView('Monthwise_Report_for_Individual_SKU')}>View Report</button>
        </div>

        <div className="report-section">
          <span className="report-name">Vendorwise QR Code Count</span>
          <button className="view-report-btn" onClick={() => handleView('Vendorwise_QR_Code_Count')}>View Report</button>
        </div>

        <div className="report-section">
          <span className="report-name">PO Received Month Wise</span>
          <button className="view-report-btn" onClick={() => handleView('PO_Received_Month_Wise')}>View Report</button>
        </div>

        <div className="report-section">
          <span className="report-name">Vendor Wise PO</span>
          <button className="view-report-btn" onClick={() => handleView('Vendor_Wise_PO')}>View Report</button>
        </div>
      </div>
    </div>
  );
};

export default Reports;

import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    useEffect,
} from "react";
interface AuthContextType {
    isLoggedIn: boolean;
    login: (token: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Provider component
export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
        !!sessionStorage.getItem("accessToken")
    );
    useEffect(() => {
        const token = sessionStorage.getItem("accessToken");
        setIsLoggedIn(!!token);
    }, []);

    const login = (token: string) => {
        sessionStorage.setItem("accessToken", token);
        setIsLoggedIn(true);
    };

    const logout = () => {
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("activeKey");
        sessionStorage.removeItem("userType");
        sessionStorage.removeItem("refreshToken");
        sessionStorage.removeItem("userData");
        sessionStorage.removeItem("accessToken");
        setIsLoggedIn(false);
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook for accessing AuthContext
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

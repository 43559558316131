import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import MyIcon from '../../../assets/icons/Purchase-Order.svg';
import { getCountOfPurchaseOrdersYearWise } from '../../../utils/services/apiService';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material'; // Import SelectChangeEvent
import './Purchase-Orders-Bar-Chart.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SalesOrdersBarChart: React.FC = () => {
  const [selectedYear, setSelectedYear] = useState<number>(2024);
  const [chartData, setChartData] = useState<number[]>(Array(12).fill(0));
  const [totalOrders, setTotalOrders] = useState<number>(0);

  useEffect(() => {
    fetchPurchaseOrdersData(selectedYear);
  }, [selectedYear]);

  const fetchPurchaseOrdersData = async (year: number) => {
    try {
      const response = await getCountOfPurchaseOrdersYearWise(year);
      if (response.status === 200 && response.data && response.data.data) {
        const monthlyData = Array(12).fill(0);
        response.data.data.CountOfPurchaseOrders.forEach((item: { month: number; count: number }) => {
          monthlyData[item.month - 1] = item.count;
        });
        setChartData(monthlyData);
        setTotalOrders(monthlyData.reduce((sum, value) => sum + value, 0));
      }
    } catch (error) {
      console.error('Error fetching purchase orders data:', error);
    }
  };

  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Purchase Orders',
        data: chartData,
        backgroundColor: 'rgb(104, 118, 255)',
        barThickness: 28,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 6,
          callback: (value) => {
            const numericValue = Number(value);
            return numericValue >= 1000 ? `${numericValue / 1000}k` : numericValue;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#f0f0f0',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: 'lightgrey',
        borderWidth: 1,
        padding: 10,
        displayColors: false,
        callbacks: {
          title: () => '',
          label: (context) => {
            const month = data.labels[context.dataIndex];
            const year = selectedYear;
            const purchaseOrders = context.parsed.y.toLocaleString();
            return [`${purchaseOrders} Purchase Orders`, `${month} ${year}`];
          },
        },
      },
    },
  };

  // Updated event handler with correct type
  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setSelectedYear(Number(event.target.value));
  };

  return (
    <div className="sales-orders-chart">
      <div className="chart-header">
        <img src={MyIcon} alt="Sales Icon" className="chart-icon" />
        <div className="header-text">
          <h2>Month Wise Purchase Order Received</h2>
          <p>For the year of {selectedYear}</p>
        </div>
        {/* Material UI Select for Year */}
        <FormControl fullWidth sx={{ width: '120px', marginTop: '4px', marginRight: '10px' }}>
          <InputLabel>Year</InputLabel>
          <Select
            value={selectedYear}
            onChange={handleYearChange}
            label="Year"
          >
            {/* <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2023}>2023</MenuItem> */}
            <MenuItem value={2024}>2024</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="chart-summary">
        <h1>{totalOrders.toLocaleString()}</h1>
        <p>Purchase orders have been received</p>
      </div>
      <div className="chart-container">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default SalesOrdersBarChart;

import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, ButtonToolbar, Container, Button, Schema, InputGroup, useToaster, Message } from 'rsuite';
import loginImage from '../../assets/contactus.png';
import { Heading, Text } from 'rsuite';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import './Login.css';
import BackButton from '../../components/BackButton';
import { triggerOtpUser, setUserPassword, userSignInUsingPassword } from '../../utils/services/apiService';
import { useAuth } from '../../utils/AuthContext';

const { StringType } = Schema.Types;

const passwordModel = Schema.Model({
    emailOrMobile: StringType()
        .isRequired('Email or Mobile Number is required')
        .addRule((value) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const mobileRegex = /^\d{10}$/;
            return emailRegex.test(value) || mobileRegex.test(value) || {
                hasError: true,
                errorMessage: 'Invalid Email or Mobile Number'
            };
        }),
    password: StringType().isRequired('Password is required'),
});

const otpModel = Schema.Model({
    emailOrMobile: StringType()
        .isRequired('Mobile Number is required')
        .addRule((value) => {
            const mobileRegex = /^\d{10}$/;
            return mobileRegex.test(value) || {
                hasError: true,
                errorMessage: 'Please enter a valid 10-digit mobile number'
            };
        }),
    otp: StringType().isRequired('OTP is required'),
});

const resetPasswordModel = Schema.Model({
    newPassword: StringType()
        .isRequired('New Password is required')
        .minLength(8, 'Password must be at least 8 characters long.')
        .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/, 'Password must contain at least one uppercase letter, one lowercase letter, and one special character.'),
    confirmPassword: StringType()
        .isRequired('Confirm Password is required')
        .addRule((value, formValue) => value === formValue.newPassword, 'Passwords do not match'),
});

interface PasswordFormValue {
    emailOrMobile: string;
    password: string;
}

interface OtpFormValue {
    emailOrMobile: string;
    otp: string;
}

interface ResetPasswordFormValue {
    newPassword: string;
    confirmPassword: string;
}

interface FormError {
    emailOrMobile?: string;
    password?: string;
    otp?: string;
    newPassword?: string;
    confirmPassword?: string;
    login?: string;
}

const Login = () => {
    const { login } = useAuth();
    const formRef = useRef<any>(null);
    const [formError, setFormError] = useState<FormError>({});
    const [passwordFormValue, setPasswordFormValue] = useState<PasswordFormValue>({
        emailOrMobile: '',
        password: '',
    });
    const [otpFormValue, setOtpFormValue] = useState<OtpFormValue>({
        emailOrMobile: '',
        otp: '',
    });
    const [resetPasswordFormValue, setResetPasswordFormValue] = useState<ResetPasswordFormValue>({
        newPassword: '',
        confirmPassword: '',
    });
    const [isOtpLogin, setIsOtpLogin] = useState(false);
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const navigate = useNavigate();
    const toaster = useToaster();

    const showNotification = (message: string, type: 'success' | 'error' | 'info' = 'info') => {
        toaster.push(
            <Message showIcon type={type}>
                {message}
            </Message>,
            { placement: 'topCenter' }
        );
    };

    const handleSubmit = async () => {
        if (formRef.current && !formRef.current.check()) {
            return;
        }

        if (resetPassword) {
            try {
                const response = await setUserPassword({
                    user_mobile_number: otpFormValue.emailOrMobile,
                    user_password: resetPasswordFormValue.newPassword
                });

                if (response.status === 200) {
                    showNotification('Password reset successful! Please login with your new password.', 'success');
                    // Reset all states to show login with password form
                    setResetPassword(false);
                    setForgotPassword(false);
                    setIsOtpLogin(false);
                    setShowOtpInput(false);
                    setPasswordFormValue({ emailOrMobile: '', password: '' });
                    setOtpFormValue({ emailOrMobile: '', otp: '' });
                    setResetPasswordFormValue({ newPassword: '', confirmPassword: '' });
                } else {
                    setFormError({ login: 'Failed to reset password. Please try again.' });
                    showNotification('Failed to reset password. Please try again.', 'error');
                }
            } catch (error) {
                setFormError({ login: 'An error occurred. Please try again.' });
                showNotification('An unexpected error occurred during password reset.', 'error');
            }
        } else if (forgotPassword) {
            try {
                const response = await triggerOtpUser({
                    user_mobile_number: otpFormValue.emailOrMobile,
                    type: 'validate_otp',
                    otp: otpFormValue.otp
                });

                // Check if the response indicates an unregistered number
                if (response.data?.message === "Entered mobile number not registered with us!❌❌❌" || response.data?.status === false) {
                    setFormError({ emailOrMobile: 'This mobile number is not registered with us.' });
                    showNotification('This mobile number is not registered with us.', 'error');
                    return;
                }

                if (response.status === 200) {
                    showNotification('OTP validated successfully!', 'success');
                    setResetPassword(true);
                } else {
                    setFormError({ login: 'Invalid OTP. Please try again.' });
                    showNotification('Invalid OTP. Please try again.', 'error');
                }
            } catch (error) {
                setFormError({ login: 'An error occurred. Please try again.' });
                showNotification('An unexpected error occurred while validating OTP.', 'error');
            }
        } else if (isOtpLogin) {
            try {
                const response = await triggerOtpUser({
                    user_mobile_number: otpFormValue.emailOrMobile,
                    type: 'validate_otp',
                    otp: otpFormValue.otp
                });

                // Check if the response indicates an unregistered number
                if (response.data?.message === "Entered mobile number not registered with us!❌❌❌" || response.data?.status === false) {
                    setFormError({ emailOrMobile: 'This mobile number is not registered with us.' });
                    showNotification('This mobile number is not registered with us.', 'error');
                    return;
                }

                if (response.status === 200 && response.data.message === "Login successfully!✅") {
                    console.log(response.data);
                    sessionStorage.setItem("userData", JSON.stringify(response?.data?.data))
                    sessionStorage.setItem("accessToken", response.data?.data?.accessAndRefreshToken?.accessToken);
                    sessionStorage.setItem("refreshToken", response.data?.data?.accessAndRefreshToken?.refreshToken);
                    sessionStorage.setItem("userType", response.data?.data?.type_of_user);
                    login(response.data?.data?.accessAndRefreshToken?.accessToken || "")

                    showNotification('Login successful!', 'success');
                    localStorage.setItem('isAuthenticated', 'true');
                    navigate('/dashboard');
                } else {
                    setFormError({ login: 'Invalid OTP. Please try again.' });
                    showNotification('Invalid OTP. Please try again.', 'error');
                }
            } catch (error) {
                setFormError({ login: 'An error occurred. Please try again.' });
                showNotification('An unexpected error occurred during OTP login.', 'error');
            }
        }  else {
            try {
                const response = await userSignInUsingPassword({
                    deviceType: 'web',
                    user_password: passwordFormValue.password,
                    ...(passwordFormValue.emailOrMobile.includes('@')
                        ? { user_email: passwordFormValue.emailOrMobile }
                        : { user_mobile_number: passwordFormValue.emailOrMobile })
                });

                if (response.status === 200 && response.data.message === "Login successfully!✅") {
                    console.log(response.data);
                    sessionStorage.setItem("userData", JSON.stringify(response?.data?.data))
                    sessionStorage.setItem("accessToken", response.data?.data?.accessAndRefreshToken?.accessToken);
                    sessionStorage.setItem("refreshToken", response.data?.data?.accessAndRefreshToken?.refreshToken);
                    sessionStorage.setItem("userType", response.data?.data?.type_of_user);
                    login(response.data?.data?.accessAndRefreshToken?.accessToken || "")

                    showNotification('Login successful!', 'success');
                    localStorage.setItem('isAuthenticated', 'true');
                    navigate('/dashboard');
                } else {
                    setFormError({ login: 'Invalid credentials. Please try again.' });
                    showNotification('Invalid login credentials. Please try again.', 'error');
                }
            } catch (error) {
                setFormError({ login: 'An error occurred. Please try again.' });
                showNotification('An unexpected error occurred during login.', 'error');
            }
        }
    };

    const validateMobileNumber = (value: string) => {
        const mobileRegex = /^\d{10}$/;
        return mobileRegex.test(value);
    };

    const handlePasswordChange = (value: Record<string, any>) => {
        setPasswordFormValue(value as PasswordFormValue);
    };

    const handleOtpChange = (value: Record<string, any>) => {
        setOtpFormValue(value as OtpFormValue);
    };

    const handleResetPasswordChange = (value: Record<string, any>) => {
        setResetPasswordFormValue(value as ResetPasswordFormValue);
    };

    const toggleLoginMethod = () => {
        setIsOtpLogin(!isOtpLogin);
        setShowOtpInput(false);
        setFormError({});
        setOtpFormValue({ emailOrMobile: '', otp: '' });
        setPasswordFormValue({ emailOrMobile: '', password: '' });
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleForgotPassword = () => {
        setForgotPassword(true);
        setIsOtpLogin(false);
        setShowOtpInput(false);
        setResetPassword(false);
        setFormError({});
        setOtpFormValue({ emailOrMobile: '', otp: '' });
    };

    const handleSendOtp = async () => {
        const mobileNumber = otpFormValue.emailOrMobile;

        if (validateMobileNumber(mobileNumber)) {
            try {
                const response = await triggerOtpUser({
                    user_mobile_number: mobileNumber,
                    type: 'generate_otp'
                });
                
                // Check if the response indicates an unregistered number
                if (response.data?.message === "Entered mobile number not registered with us!❌❌❌" || response.data?.status === false) {
                    setFormError({ emailOrMobile: 'This mobile number is not registered with us.' });
                    showNotification('This mobile number is not registered with us.', 'error');
                    return;
                }
                
                if (response.status === 200) {
                    showNotification('OTP sent to your mobile number!', 'success');
                    setShowOtpInput(true);
                } else {
                    setFormError({ emailOrMobile: 'Failed to send OTP. Please try again.' });
                    showNotification('Failed to send OTP. Please try again.', 'error');
                }
            } catch (error) {
                setFormError({ emailOrMobile: 'An error occurred. Please try again.' });
                showNotification('An error occurred while sending OTP. Please try again.', 'error');
            }
        } else {
            setFormError({ emailOrMobile: 'Please enter a valid 10-digit mobile number.' });
            showNotification('Please enter a valid 10-digit mobile number.', 'error');
        }
    };

    const leftSide = () => (
        <div className='leftSide flex2'>
            <img className='image' src={loginImage} alt="Login" />
        </div>
    );

    const rightSide = () => (
        <div className='flex1 rightSide'>
              {forgotPassword && (
                <div style={{ marginTop: '20px', marginBottom: '0px' }}>
                    <BackButton redirectTo="/reports" />
                </div>
            )}
            <Form
                ref={formRef}
                className='formGroup'
                model={resetPassword ? resetPasswordModel : forgotPassword ? otpModel : isOtpLogin ? otpModel : passwordModel}
                onSubmit={handleSubmit}
                onChange={resetPassword ? handleResetPasswordChange : forgotPassword ? handleOtpChange : isOtpLogin ? handleOtpChange : handlePasswordChange}
                onCheck={(formError: FormError) => setFormError(formError)}
                formValue={resetPassword ? resetPasswordFormValue : forgotPassword ? otpFormValue : isOtpLogin ? otpFormValue : passwordFormValue}
                formError={formError}
            >
                <div className='formHeader'>
                    {resetPassword ? (
                        <Heading level={4}>Reset Password</Heading>
                    ) : forgotPassword ? (
                        <Heading level={4}>Forgot Password</Heading>
                    ) : (
                        <Heading level={4}>Welcome back!</Heading>
                    )}
                    <Text muted>
                        {resetPassword
                            ? 'Enter your new password below.'
                            : forgotPassword
                                ? 'Please enter your mobile number and we will send you an OTP to reset your password.'
                                : isOtpLogin
                                    ? 'Please enter your mobile number for OTP login.'
                                    : 'Please enter your login details.'}
                    </Text>
                </div>
                {formError.login && <Form.ErrorMessage style={{ marginBottom: '10px' }}>{formError.login}</Form.ErrorMessage>}

                {resetPassword ? (
                    <>
                        <Form.Group controlId="newPassword">
                            <Form.ControlLabel>New Password <span style={{ color: 'red' }}>*</span></Form.ControlLabel>
                            <InputGroup inside>
                                <Form.Control
                                    name="newPassword"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Enter New Password"
                                    autoComplete="off"
                                />
                                <InputGroup.Button onClick={toggleShowPassword}>
                                    {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                                </InputGroup.Button>
                            </InputGroup>
                            <Text muted style={{ marginTop: '8px' }}>
                                • Minimum 8 characters<br />
                                • Includes uppercase, lowercase, special characters
                            </Text>
                        </Form.Group>
                        <Form.Group controlId="confirmPassword">
                            <Form.ControlLabel>Confirm Password <span style={{ color: 'red' }}>*</span></Form.ControlLabel>
                            <InputGroup inside>
                                <Form.Control
                                    name="confirmPassword"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder="Confirm New Password"
                                />
                                <InputGroup.Button onClick={toggleShowConfirmPassword}>
                                    {showConfirmPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                                </InputGroup.Button>
                            </InputGroup>
                        </Form.Group>
                    </>
                ) : !forgotPassword ? (
                    !isOtpLogin ? (
                        <>
                            <Form.Group controlId="emailOrMobile">
                                <Form.ControlLabel>Email or Mobile Number <span style={{ color: 'red' }}>*</span></Form.ControlLabel>
                                <Form.Control name="emailOrMobile" placeholder="Enter Email or Mobile Number" />
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.ControlLabel>Password <span style={{ color: 'red' }}>*</span></Form.ControlLabel>
                                <InputGroup inside>
                                    <Form.Control
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Enter Password"
                                        autoComplete="off"
                                    />
                                    <InputGroup.Button onClick={toggleShowPassword}>
                                        {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                                    </InputGroup.Button>
                                </InputGroup>
                                <div style={{ textAlign: 'right', margin: '0 0 10px 0' }}>
                                    <Text
                                        muted
                                        style={{ cursor: 'pointer', color: '#008FF5' }}
                                        onClick={handleForgotPassword}
                                    >
                                        Forgot Password?
                                    </Text>
                                </div>
                            </Form.Group>
                        </>
                    ) : (
                        <>
                            <Form.Group controlId="emailOrMobile">
                                <Form.ControlLabel>Mobile Number <span style={{ color: 'red' }}>*</span></Form.ControlLabel>
                                <InputGroup inside>
                                    <Form.Control
                                        name="emailOrMobile"
                                        placeholder="Enter 10-digit Mobile Number"
                                        type="tel"
                                        maxLength={10}
                                    />
                                    <InputGroup.Button onClick={handleSendOtp}>
                                        Send OTP
                                    </InputGroup.Button>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="otp">
                                <Form.ControlLabel>OTP <span style={{ color: 'red' }}>*</span></Form.ControlLabel>
                                <Form.Control
                                    name="otp"
                                    placeholder="Enter OTP"
                                    type="text"
                                    maxLength={6}
                                />
                            </Form.Group>
                        </>
                    )
                ) : (
                    <>
                    
                        <Form.Group controlId="emailOrMobile">
                            <Form.ControlLabel>Mobile Number <span style={{ color: 'red' }}>*</span></Form.ControlLabel>
                            <InputGroup inside>
                                <Form.Control
                                    name="emailOrMobile"
                                    placeholder="Enter 10-digit Mobile Number"
                                    type="tel"
                                    maxLength={10}
                                />
                                <InputGroup.Button onClick={handleSendOtp}>
                                    Send OTP
                                </InputGroup.Button>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="otp">
                            <Form.ControlLabel>OTP <span style={{ color: 'red' }}>*</span></Form.ControlLabel>
                            <Form.Control
                                name="otp"
                                placeholder="Enter OTP"
                                type="text"
                                maxLength={6}
                            />
                        </Form.Group>
                    </>
                )}

                <Form.Group>
                    <ButtonToolbar>
                        <Button appearance="primary" type="submit" style={{ width: '100%', backgroundColor: '#EC1B3B' }}>
                            {resetPassword ? 'Submit' : forgotPassword ? 'Reset Password' : 'Login'}
                        </Button>
                    </ButtonToolbar>
                </Form.Group>

                {!resetPassword && !forgotPassword && (
                    <>
                        <Text style={{ textAlign: 'center', display: 'block', margin: '10px 0' }}>
                            or continue with
                        </Text>

                        {!isOtpLogin && (
                            <Button
                                appearance="default"
                                onClick={toggleLoginMethod}
                                style={{
                                    width: '100%',
                                    marginBottom: '10px',
                                    backgroundColor: 'white',
                                    border: '1px solid #EC1B3B',
                                    color: '#EC1B3B',
                                    fontWeight: 'bold',
                                }}
                            >
                                Login with OTP
                            </Button>
                        )}

                        {isOtpLogin && (
                            <Button
                                appearance="default"
                                onClick={toggleLoginMethod}
                                style={{
                                    width: '100%',
                                    marginBottom: '10px',
                                    backgroundColor: 'white',
                                    border: '1px solid #EC1B3B',
                                    color: '#EC1B3B',
                                    fontWeight: 'bold',
                                }}
                            >
                                Login with Password
                            </Button>
                        )}

                        <Text style={{ textAlign: 'center', display: 'block', margin: '10px 0', fontSize: '12px' }}>
                            By clicking you agree to our <strong>Terms of service</strong> and <strong>Privacy Policy</strong>
                        </Text>
                    </>
                )}
            </Form>
        </div>
    );

    return (
        <Container className='loginScreen'>
            <div className='loginPage'>
                {leftSide()}
                {rightSide()}
            </div>
        </Container>
    );
};

export default Login;
import React, { useState, useEffect, useRef } from 'react';
import { Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomTable, { ColumnConfig } from "../../components/CustomTable";
import Header from '../dashboard/components/Header';
import './PurchaseOrder.css';
import { 
    fetchPurchaseOrders, 
    uploadPurchaseOrder, 
    generateQrPoLevel,
    downloadQrForAdmin 
} from '../../utils/services/apiService';
import UploadResultPopup from './UploadResultPopup';

interface DataItem {
    id: string;
    sr_no: number;
    purchase_order_number: string;
    no_of_line_items: string;
    purchase_order_date: string;
    vendor_name: string;
    plant: string;
    purchase_order_status: string;
    purchase_order_completion: string;
}

export interface ProcessedData {
    purchase_order_number: string;
    status: string;
    short_message: string;
    message?: string;
    created_by?: number;
    file_upload_id?: number;
}

export interface UnprocessedData {
    entry: {
        [key: string]: string;
    };
    reason: string;
}

const PurchaseOrder: React.FC = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<DataItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [uploadStatus, setUploadStatus] = useState<string | null>(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [processedData, setProcessedData] = useState<ProcessedData[]>([]);
    const [unprocessedData, setUnprocessedData] = useState<UnprocessedData[]>([]);
    const [downloadStatus, setDownloadStatus] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const typeOfUser = sessionStorage.getItem('userType');

    useEffect(() => {
        loadPurchaseOrders();
    }, []);

    const loadPurchaseOrders = async () => {
        setLoading(true);
        try {
            const { data } = await fetchPurchaseOrders();
            if (data.status === 200 && data.data?.purchaseOrders) {
                const normalizedData = data.data?.purchaseOrders.map((item: any, index: number) => ({
                    sr_no: index + 1,
                    purchase_order_number: item?.purchase_order_number || "",
                    no_of_line_items: item.no_of_line_items || "",
                    purchase_order_date: item.purchase_order_date || "",
                    vendor_name: item.vendor.user_full_name || "",
                    plant: item.plant || "",
                    purchase_order_status: item.purchase_order_status || "",
                    purchase_order_completion: item.purchase_order_completion || "",
                    id: item.id
                }));
                setData(normalizedData);
            } else {
                console.error('Error fetching purchase orders:', data.statusText);
            }
        } catch (error) {
            console.error('API error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleViewDetails = (rowData: DataItem) => {
        navigate(`/purchase-order-details/${rowData.purchase_order_number}`);
    };

    const handleDownload = async (rowData: DataItem) => {
        toast.info('Download in process. Please check your email for the QR codes.');
        try {
            let response;
            if (rowData.purchase_order_status.toLowerCase() === 'completed') {
                response = await downloadQrForAdmin(rowData.purchase_order_number);
            } else {
                response = await generateQrPoLevel(rowData.purchase_order_number);
            }
            
            if (response.status === 200) {
                toast.success('QR codes generation request has been processed. You will receive an email shortly.');
            } else {
                toast.error('Error processing your request. Please try again later.');
            }
        } catch (error) {
            console.error('Download error:', error);
            toast.error('Error processing your request. Please try again later.');
        }
        setTimeout(() => {
            setDownloadStatus(null);
        }, 5000);
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setUploadStatus(`Uploading ${file.name}...`);
            try {
                const response = await uploadPurchaseOrder(file);
                if (response.status === 200 && response.data.purchaseOrders) {
                    setUploadStatus(`${file.name} uploaded successfully`);
                    setProcessedData(response.data.purchaseOrders.processedData || []);
                    setUnprocessedData(response.data.purchaseOrders.unproccesedData || []);
                    setIsPopupOpen(true);
                    loadPurchaseOrders();
                } else {
                    setUploadStatus(`Error uploading ${file.name}`);
                }
            } catch (error) {
                console.error('Upload error:', error);
                setUploadStatus(`Error uploading ${file.name}`);
            } finally {
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            }
            setTimeout(() => {
                setUploadStatus(null);
            }, 3000);
        } else {
            setUploadStatus(null);
        }
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const columns: ColumnConfig[] = [
        {
            field: 'sr_no',
            headerName: 'Sr No.',
            width: 80,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-left">{params.value}</div>
        },
        {
            field: 'purchase_order_number',
            headerName: 'PO Number',
            width: 110,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'no_of_line_items',
            headerName: 'No. of Line Items',
            width: 150,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'purchase_order_date',
            headerName: 'PO Date',
            width: 110,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'vendor_name',
            headerName: 'Vendor Name',
            width: 150,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-left">{params.value}</div>
        },
        {
            field: 'plant',
            headerName: 'Plant',
            width: 110,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'purchase_order_status',
            headerName: 'PO Status',
            width: 110,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-left">{params.value}</div>
        },
        {
            field: 'purchase_order_completion',
            headerName: 'PO Completion Date',
            width: 170,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 250,
            renderHeader: (params) => <div className="table-header table-header-center">{params.colDef.headerName}</div>,
            renderCell: (params) => {
                const isVendor = typeOfUser === 'vendor';
                const isCompleted = params.row.purchase_order_status.toLowerCase() === 'completed';
                const showDownloaded = isVendor && isCompleted;

                return (
                    <div className="button-group">
                        <Button
                            variant="contained"
                            onClick={() => handleViewDetails(params.row as DataItem)}
                            className="button-rounded"
                        >
                            View details
                        </Button>
                        {showDownloaded ? (
                            <Button
                                variant="outlined"
                                className="button-rounded"
                                style={{
                                    cursor: '#666',
                                    backgroundColor: 'white',
                                    color: '#666',
                                    pointerEvents: 'none',
                                    borderColor: '#666',
                                }}
                            >
                                Downloaded
                            </Button>
                        ) : (
                            <Button
                                variant="outlined"
                                onClick={() => handleDownload(params.row as DataItem)}
                                className="button-rounded"
                            >
                                Download
                            </Button>
                        )}
                    </div>
                );
            },
        }
    ];

    return (
        <div className="screen">
            <Header/>
            <ToastContainer  
            position="top-center"
            autoClose={4000} />
            <div className="table">
                <div className="table-header-container">
                    <Typography variant="body1" className="breadcrumb">
                        Purchase Order
                    </Typography>
                    {typeOfUser === 'admin' && (
                        <Button
                            variant="contained"
                            component="label"
                            className="upload-button"
                        >
                            Upload Purchase Order
                            <input
                                ref={fileInputRef}
                                type="file"
                                hidden
                                accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={handleFileUpload}
                            />
                        </Button>
                    )}
                </div>
                {uploadStatus && (
                    <Typography variant="body2" className="upload-status">
                        {uploadStatus}
                    </Typography>
                )}
                {downloadStatus && (
                    <Typography variant="body2" className="download-status">
                        {downloadStatus}
                    </Typography>
                )}
                <div className="custom-table">
                    <CustomTable
                        editMode="row"
                        data={data}
                        columns={columns}
                        loading={loading}
                        getRowId={(data: any) => data.id}
                    />
                </div>
            </div>
            <UploadResultPopup
                open={isPopupOpen}
                onClose={handleClosePopup}
                processedData={processedData}
                unprocessedData={unprocessedData}
            />
        </div>
    );
};

export default PurchaseOrder;
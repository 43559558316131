import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import CustomTable, { ColumnConfig } from "../../../components/CustomTable";
import Header from '../../dashboard/components/Header';
import BackButton from '../../../components/BackButton';
import { getPurchaseOrderUploadLogs } from '../../../utils/services/apiService';

// Interface for the API response
interface PoUploadLogItem {
    id: number;
    purchase_order_number: string;
    status: string;
    short_message: string;
    created_at: string;
    updated_at: string;
    files: {
        id: number;
        file_name: string;
        operation_type: string;
        status: string;
        created_at: string;
        updated_at: string;
    };
    user: {
        user_id: number;
        user_full_name: string;
        user_email: string;
        user_mobile_number: string;
        user_type: string;
        user_code: string | null;
        binding_ip: string;
        created_at: string;
        updated_at: string;
    };
}

const PoUploadLogs: React.FC = () => {
    const [data, setData] = useState<PoUploadLogItem[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadPoUploadLogData();
    }, []);

    const loadPoUploadLogData = async () => {
        setLoading(true);
        try {
            const response = await getPurchaseOrderUploadLogs();
            if (response.data) {
                setData(response.data);
            }
        } catch (error) {
            console.error('Error fetching PO upload logs:', error);
        } finally {
            setLoading(false);
        }
    };

    const columns: ColumnConfig[] = [
        { 
            field: 'purchase_order_number', 
            headerName: 'PO Number', 
            width: 110 
        },
        { 
            field: 'status', 
            headerName: 'Status', 
            width: 150,
            renderCell: (params: any) => (
                <span style={{
                    textTransform: 'capitalize'
                }}>
                    {params.value}
                </span>
            )
        },
        { 
            field: 'short_message', 
            headerName: 'Message', 
            width: 250 
        },
        { 
            field: 'created_at', 
            headerName: 'Created At', 
            width: 200,
            renderCell: (params: any) => (
                <span>
                    {new Date(params.value).toLocaleString()}
                </span>
            )
        },
    ];

    return (
        <div className="screen">
            <Header />
            <div className="table">
                <div className="table-header-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box display="flex" alignItems="center" gap={2}>
                        <BackButton redirectTo="/logs" />
                        <Typography variant="h6" className="breadcrumb">
                            PO Upload Logs
                        </Typography>
                    </Box>
                </div>

                <div className="custom-table">
                    <CustomTable
                        editMode="row"
                        data={data}
                        columns={columns}
                        loading={loading}
                        getRowId={(row: any) => row.id}
                    />
                </div>
            </div>
        </div>
    );
};

export default PoUploadLogs;
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

interface PublicRouteProps {
    element: React.ComponentType;
    redirectTo?: string;
}

const PublicRoute: React.FC<PublicRouteProps> = ({
    element: Component,
    redirectTo = "/dashboard",
}) => {
    const { isLoggedIn } = useAuth();
    return isLoggedIn ? <Navigate to={redirectTo} /> : <Component />;
};

export default PublicRoute;

import React from 'react';
import { Link } from 'react-router-dom';
import './SecuritySettings.css';
import Header from '../../pages/dashboard/components/Header';

const SecuritySettings: React.FC = () => {
  const userType = sessionStorage.getItem('userType'); // Retrieve user type from session storage

  return (
    <div>
      <Header />
      <div className="security-settings-container">
        <h2 className="security-settings-title">Security Settings</h2>

        <div className="security-settings-section">
          <span className="section-name">Expiry Policy</span>
          <button className="action-btn">Edit</button>
        </div>

        <div className="security-settings-section">
          <span className="section-name">Password Policies</span>
          <button className="action-btn">Edit</button>
        </div>

        <div className="security-settings-section">
          <span className="section-name">Two-Factor Authentication (2FA)</span>
          <button className="action-btn">Edit</button>
        </div>

        {/* Render this section only for Admin users */}
        {userType === 'admin' && (
          <div className="security-settings-section">
            <span className="section-name">View System Activity Logs</span>
            <Link to="/logs">
              <button className="action-btn">View</button>
            </Link>
          </div>
        )}

        <div className="security-settings-section">
          <span className="section-name">Access Help Documentation or Support</span>
          <button className="action-btn">Help</button>
        </div>

        <div className="security-settings-section">
          <span className="section-name">Session + Geo Location Log</span>
          <button className="action-btn">View</button>
        </div>
      </div>
    </div>
  );
};

export default SecuritySettings;

import React, { useState, useEffect } from 'react';
import { Typography, Button, Select, MenuItem, FormControl, InputLabel, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CustomTable, { ColumnConfig } from "../../../components/CustomTable";
import Header from '../../dashboard/components/Header';
import BackButton from '../../../components/BackButton';
import excelIcon from '../../../assets/excel.png';
import { getQrVendorWiseMonthWiseReport, fetchUsers } from '../../../utils/services/apiService';

interface PurchaseOrderItem {
    vendorName: string;
    month: string;
    totalGenerated: number;
    totalScanned: number;
    innerQrCount: number;
    outerQrCount: number;
    conversionRate: string;
}

interface User {
    user_id: number;
    user_full_name: string;
    user_type: string;
    user_email: string;
    user_mobile_number: string;
}

const VendorwiseQRCodeCount: React.FC = () => {
    const [data, setData] = useState<PurchaseOrderItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [exportStatus, setExportStatus] = useState<string | null>(null);
    const [isFilterDialogOpen, setFilterDialogOpen] = useState(false);
    const [year, setYear] = useState('2024'); // Default to current year
    const [vendorId, setVendorId] = useState('');
    const [vendors, setVendors] = useState<User[]>([]);
    const [userType, setUserType] = useState<string>('');

    useEffect(() => {
        // Get user type from session storage
        const userTypeFromSession = sessionStorage.getItem('userType') || '';
        setUserType(userTypeFromSession.toLowerCase());

        // If user is a vendor, set their ID from session storage
        if (userTypeFromSession.toLowerCase() === 'vendor') {
            const vendorIdFromSession = sessionStorage.getItem('userId') || '';
            setVendorId(vendorIdFromSession);
        }

        loadVendors();
        loadPurchaseOrderData();
    }, []);

    const loadVendors = async () => {
        try {
            const response = await fetchUsers();
            if (response.data.status === 200) {
                // Filter only vendor type users
                const vendorsList = response.data.data.filter((user: User) => 
                    user.user_type.toLowerCase() === 'vendor'
                );
                setVendors(vendorsList);
            }
        } catch (error) {
            console.error('Error loading vendors:', error);
        }
    };

    const loadPurchaseOrderData = async () => {
        setLoading(true);
        try {
            // If user is a vendor, always use their ID
            const effectiveVendorId = userType === 'vendor' 
                ? parseInt(sessionStorage.getItem('userId') || '0') 
                : (parseInt(vendorId) || 0);

            const response = await getQrVendorWiseMonthWiseReport({
                year: parseInt(year),
                exportExcel: false,
                vendor_id: effectiveVendorId
            });

            if (response.data.success) {
                const purchaseOrdersData = response.data.purchaseOrdersItemDetails[0] || [];
                setData(purchaseOrdersData);
            }
        } catch (error) {
            console.error('Error loading purchase order data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleExport = async () => {
        setExportStatus('Exporting data...');
        try {
            const effectiveVendorId = userType === 'vendor' 
                ? parseInt(sessionStorage.getItem('userId') || '0') 
                : (parseInt(vendorId) || 0);

            const response = await getQrVendorWiseMonthWiseReport({
                year: parseInt(year),
                exportExcel: true,
                vendor_id: effectiveVendorId
            });

            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'vendor_wise_qr_report.xlsx';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(url);

            setExportStatus('Export successful! The data has been downloaded.');
            setTimeout(() => setExportStatus(null), 5000);
        } catch (error) {
            console.error('Error exporting data:', error);
            setExportStatus('Export failed. Please try again later.');
            setTimeout(() => setExportStatus(null), 5000);
        }
    };

    const handleFilterDialogOpen = () => setFilterDialogOpen(true);
    const handleFilterDialogClose = () => setFilterDialogOpen(false);

    const handleApplyFilter = () => {
        loadPurchaseOrderData();
        handleFilterDialogClose();
    };

    const columns: ColumnConfig[] = [
        { field: 'vendorName', headerName: 'Vendor Name', width: 180 },
        { field: 'month', headerName: 'Month', width: 120 },
        { field: 'totalGenerated', headerName: 'Total Generated', width: 150 },
        { field: 'totalScanned', headerName: 'Total Scanned', width: 150 },
        { field: 'innerQrCount', headerName: 'Inner QR Count', width: 150 },
        { field: 'outerQrCount', headerName: 'Outer QR Count', width: 150 },
        { field: 'conversionRate', headerName: 'Conversion Rate', width: 150 },
    ];

    const years = ['2021', '2022', '2023', '2024'];

    return (
        <div className="screen">
            <Header />
            <div className="table">
                <div className="table-header-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box display="flex" alignItems="center" gap={2}>
                <BackButton redirectTo="/reports" />
                    <Typography variant="h6" className="breadcrumb">
                    Vendorwise QR Code Count
                    </Typography>
                    </Box>
                    <Box display="flex" gap={2}>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: 'whitesmoke', color: '#007d3c', fontWeight: 'bold', border: '1px solid #ccc' }}
                            onClick={handleExport}
                            className="export-button"
                        >
                            <img src={excelIcon} alt="Excel Icon" style={{ width: 20, height: 20, marginRight: 8 }} />
                            Export
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFilterDialogOpen}
                            className="filter-button"
                        >
                            <FilterAltIcon style={{ color: 'white' }} />
                            Filter
                        </Button>
                    </Box>
                </div>

                {exportStatus && (
                    <Typography variant="body2" className="export-status" style={{ marginTop: 16 }}>
                        {exportStatus}
                    </Typography>
                )}

                <CustomTable
                    editMode="row"
                    data={data}
                    columns={columns}
                    loading={loading}
                    getRowId={(data: any) => `${data.vendorName}-${data.month}`}
                />

                <Dialog open={isFilterDialogOpen} onClose={handleFilterDialogClose} maxWidth="sm" fullWidth>
                    <DialogTitle>Filter Purchase Order Data</DialogTitle>
                    <DialogContent>
                        <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel>Year</InputLabel>
                            <Select
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                                label="Year"
                            >
                                {years.map((yearOption) => (
                                    <MenuItem key={yearOption} value={yearOption}>
                                        {yearOption}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* Show vendor field only for admin and analytics users */}
                        {(userType === 'admin' || userType === 'analytics') && (
                            <FormControl variant="outlined" fullWidth margin="normal">
                                <InputLabel>Vendor</InputLabel>
                                <Select
                                    value={vendorId}
                                    onChange={(e) => setVendorId(e.target.value)}
                                    label="Vendor"
                                >
                                    <MenuItem value="">All Vendors</MenuItem>
                                    {vendors.map((vendor) => (
                                        <MenuItem key={vendor.user_id} value={vendor.user_id.toString()}>
                                            {vendor.user_full_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleFilterDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleApplyFilter} color="primary" variant="contained">
                            Apply Filter
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
};

export default VendorwiseQRCodeCount;
import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import Layout from "../components/Layout";
import { useAuth } from "./AuthContext";

interface PrivateRouteProps {
  element: React.ComponentType;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element: Component }) => {
  const { isLoggedIn } = useAuth();

  return isLoggedIn ? (
    <Layout>
      <Component />
    </Layout>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
